import { action, reload, revalidate, json } from "@solidjs/router";
import { _createNewCart } from "../roma-api/cart";
import { retrieveCart } from "./useCart";
import { setSession, getSession } from "../session";
import { _patchCurrentCart } from "../roma-api/cart";
import { listCarts } from "./useListCarts";

export const createCartAction = action(async (name?: string) => {
  "use server";
  console.log("calling createCartAction, name: ", name)
  const response = await _createNewCart(name);

  console.log("response from _createNewCart: ", response);

  // * (if CurrentCartID is updated in db) revalidate retrieveCart (which should be called with 'current' ?)
  // return json(response, {revalidate: retrieveCart.key })
  // * or
  // return reload({revalidate: retrieveCart.key})
  // * or
  // await revalidate(retrieveCart.key);
  // return response;


  // * OR (if CurrentCartID is not updated in db) --> This should trigger a refetch of the current cart anyways..

  // update current cart in db
  await _patchCurrentCart(response.ID);

  await setSession({cart_id: response.ID});

  await revalidate( retrieveCart.key) // putting this here does not cause a loop, placing it in array in reload() fn causes loop...
  return reload({ revalidate: [getSession.key, listCarts.key] });
  // return json(response, {revalidate: [getSession.key]})
});
