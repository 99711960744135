import { action, reload, revalidate } from "@solidjs/router";
import { _removeAllItemsFromCart, _patchCurrentCart } from "../roma-api/cart";
import {  getSession } from "../session";
import { retrieveCart, listCarts } from "./";

export const clearCartAction = action(async (id?: string) => {
  "use server";

  const response = await _removeAllItemsFromCart(id);

  console.log("clear cart response >> ", response)

  return await revalidate([retrieveCart.key]) //TODO - why revalidate over reload, vice versa?
  // return await revalidate([getSession.key, listCarts.key, retrieveCart.key])
});
