import { cache, createAsync } from "@solidjs/router";
import { _retrieveCart } from "../roma-api/cart";
import { Accessor } from "solid-js";

export const retrieveCart = cache(
  (id: string = "current") => _retrieveCart(id),
  "cart"
);

// TODO - See below examples

// If using this pattern of providing a 'use' version that wraps the createAsync
// the argument needs to be an accessor, otherwise the createAsync is not
// tracking on anything..?

// ---> WILL NOT BE REACTIVE TO CHANGES IN 'id'
// export const useCart = (
//   id: string = "current",
//   options: = { deferStream: false }
// ) => createAsync(() => retrieveCart(id), options);

// ---> WILL BE REACTIVE, 'id' should be passes as accessor, ie. ()=>session.cart_id
export const useCart = (
  id: () => string = () => "current",
  options = { deferStream: false }
) => createAsync(() => retrieveCart(id()), options);
