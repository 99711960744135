import { action, reload, revalidate } from "@solidjs/router";
import { _deleteSingleCart, _patchCurrentCart } from "../roma-api/cart";
import { updateCurrentCartAction } from "./updateCurrentCart";
import { setSession, getSession } from "../session";
import { retrieveCart, listCarts } from "./";

export const deleteCartAction = action(async (id?: string) => {
  "use server";

  const response = await _deleteSingleCart(id);

  await _patchCurrentCart(response.CurrentCartID);

  await setSession({ cart_id: response.CurrentCartID });


  // TODO - both below work (revalidate / reload({revalidate})) - which is appropraite to use?
  return await revalidate([getSession.key, listCarts.key, retrieveCart.key])

  // return reload({
  //   revalidate: [getSession.key, listCarts.key, retrieveCart.key],
  // });
});
