import { action, reload, revalidate } from "@solidjs/router";
import { _patchCurrentCart } from "../roma-api/cart";
import { revalidateOnSession } from "../session";
import { getSession, setSession } from "../session";
import { retrieveCart } from "./useCart";

export const updateCurrentCartAction = action(async (id: string) => {
  "use server";

  const response = await _patchCurrentCart(id);

  // TODO - throw an error here, or in _patchCurrentCart ? if thrown there, will an errorBoundary in component catch?

  // check higher up the chain - await elsewhere?
  await setSession({ cart_id: id });
  await revalidate( retrieveCart.key) // putting this here does not cause a loop, placing it in array in reload() fn causes loop...
  return reload({ revalidate: [getSession.key] });
  // await revalidate(getSession.key); //! endless loop
  // return reload({ revalidate: revalidateOnSession }); //! endless loop
});
