import { cache } from "@solidjs/router";
import { retrieveEstimate } from "../roma-api/cart";
import { framePayloadGenerator } from "~/utils/builtFrames";
import { photoFrameSizes, galleryFrameSizes } from "~/utils/builtFrames";
import { GALLERYFRAME, PHOTOFRAME } from "~/utils/products";

export const useBulkFrameEstimates = cache(
  async (sku: string, type: typeof GALLERYFRAME | typeof PHOTOFRAME) => {
    const list = (
      type === GALLERYFRAME ? galleryFrameSizes : photoFrameSizes
    ).map((item) => item.code);

    const formatted = framePayloadGenerator(sku, list, type)

    return retrieveEstimate(formatted);
  },


  "estimates"
);
