import { action, reload, revalidate } from "@solidjs/router";
import { _sendItemToCart } from "../roma-api/cart";
import { retrieveCart } from "./useCart";

export const addToCartAction = action(async (item: any, cart_id?: string) => {
  "use server";

  const response = await _sendItemToCart(item, cart_id);
  // TODO deal w errors

  return await revalidate(retrieveCart.key)
});
