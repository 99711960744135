import { GALLERYFRAME } from "./products";
/**
 * minimum width in inches required for single wedge to be an option
 */
export const minWidthForSingleWedge: number = 0.5;

/**
 * minimum width in inches required for double wedge to be an option
 */
export const minWidthForDoubleWedge: number = 2;

/**
 * array values represent [width, height]
 */
type GFSizeDetail = {
  size: [number, number];
  mats: {
    narrow?: [number, number];
    wide?: [number, number];
  };
};
/**
 * array values represent [width, height]
 */
type PFSizeDetail = {
  size: [number, number];
};

/**
 * available sizes for gallery frames, including mat sizes
 */
export const gfSizesByCode: Record<number | string, GFSizeDetail> = {
  // 810: {
  //   size: [8, 10],
  //   mats: {
  //     narrow: [5, 7],
  //     wide: [4, 6],
  //   },
  // },
  // 1114: {
  //   size: [11, 14],
  //   mats: {
  //     narrow: [8, 10],
  //     wide: [5, 7],
  //   },
  // },
  // 1212: {
  //   size: [12, 12],
  //   mats: {
  //     narrow: [10, 10],
  //     wide: [8, 8],
  //   },
  // },
  // 1216: {
  //   size: [12, 16],
  //   mats: {
  //     narrow: [8, 12],
  //     wide: [8, 10],
  //   },
  // },
  // 1616: {
  //   size: [16, 16],
  //   mats: {
  //     narrow: [14, 14],
  //     wide: [12, 12],
  //   },
  // },
  1620: {
    size: [16, 20],
    mats: {
      narrow: [11, 14],
      wide: [8, 10],
    },
  },
  1824: {
    size: [18, 24],
    mats: {
      narrow: [16, 20],
      wide: [12, 18],
    },
  },
  2020: {
    size: [20, 20],
    mats: {
      narrow: [16, 16],
      wide: [11, 14],
    },
  },
  2436: {
    size: [24, 36],
    mats: {
      wide: [20, 30],
    },
  },
};

export const galleryFrameSizes = Object.entries(gfSizesByCode).map(
  ([code, content]) => ({
    code: code,
    width: content.size[0],
    height: content.size[1],
  })
);


export const pfSizesByCode: Record<number | string, PFSizeDetail> = {
  46: {
    size: [4, 6],
  },
  55: {
    size: [5, 5],
  },
  57: {
    size: [5, 7],
  },
  88: {
    size: [8, 8],
  },
  810: {
    size: [8, 10],
  },
};

export const photoFrameSizes = Object.entries(pfSizesByCode).map(
  ([code, content]) => ({
    code: code,
    width: content.size[0],
    height: content.size[1],
  })
);

/**
 * Determines if a joined frame is 'oversized' by ground shipping logic.
 * Uses logic provided by Tanya (girth + longest side) cannot exceed 165.
 * Girth is caclulated as 2 shortest sides multiplied by 2 and added.
 * Additional buffer is added to all sides for packing materials.
 * @param width
 * @param height
 * @param sizing
 * @param mouldingWidth
 * @param mouldingHeight
 * @param packingBuffer
 * @returns
 */
export const isJoinOversized = (
  width: number,
  height: number,
  sizing: "I" | "O",
  mouldingWidth: number,
  mouldingHeight: number,
  packingBuffer: number = 3
): boolean => {
  const w = width + (sizing === "I" ? mouldingWidth * 2 : 0);
  const h = height + (sizing === "I" ? mouldingWidth * 2 : 0);

  const [short, med, long] = [w, h, mouldingHeight].sort((a, b) => a - b);

  const total =
    long +
    packingBuffer * 2 +
    (med + packingBuffer * 2) * 2 +
    (short + packingBuffer * 2) * 2;

  return total > 165;
};

type FormattedPFGFLineItem = {
  Type: "galleryFrame" | "photoFrame";
  Plant: "";
  Quantity: number;
  SubItems: { SKU: string }[];
};


/** Helper function to structure an SAP Payload to get price estimate for a list of photo/galleryframes. Accepts a single sku, an array of sizes, and type.
 * @param sku Moulding number
 * @param list Array of sizes structured as {code: string}[] where 8x10 code is 810
 * @param type either 'galleryFrame' or 'photoFrame'
 * @returns Structured payload to submit to /estimate endpoint
 */
export const framePayloadGenerator = (
  sku: string,
  // list: { code: string | number }[],
  list: string[],
  type: string
) => {
  const payload = list.reduce((memo, item) => {
    memo.push({
      Type: `${type === GALLERYFRAME ? "galleryFrame" : "photoFrame"}`,
      Plant: "",
      Quantity: 1,
      SubItems: [
        {
          SKU: `${sku}${type === GALLERYFRAME ? "GF" : ""}-${item}${
            type === GALLERYFRAME ? "M1" : ""
          }`,
        },
      ],
    });
    return memo;
  }, [] as FormattedPFGFLineItem[]);

  return payload;
};
