import { type LineEstimate } from "./types";

export const retrieveEstimate = async (
  payload: any
): Promise<LineEstimate[]> => {
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/cart/estimate`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_TESTER_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );

  if (!response.ok) {
    // TODO custom error handling...
    throw new Error("Estimate Error");
  }

  const data = await response.json();
  return data;
};


// ! possible errors:
// {
//   "Code": "MALFORMED_REQUEST",
//   "Message": "The request you submitted seems to be malformed."
// }
